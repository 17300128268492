import Markup from "./jsx";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Markup />
    </BrowserRouter>
  );
}

export default App;
