import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Footer from "../../layout/Footer";
import Loader from "../../layout/Loader";
import Menu from "../../layout/Menu";

const UsersLog = () => {
  const [logs, setLogs] = useState([]);
  const [displayLogs, setDisplayLogs] = useState([]);
  const rows = [10, 20, 30];
  const [logsPerPage, setLogsPerPage] = useState(rows[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    getLogsData();
  }, []);

  const getLogsData = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/user-logs";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success === true) {
          let res = response.data.data;
          let arr = [];
          res.map((elem, index) => {
            arr.push({ ...elem, indexs: index + 1 });
          });
          setLogs(arr);
        }
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
      .catch((error) => {
        console.log("Errors", error);
        setLoader(false);
      });
  };

  useEffect(() => {
    setDisplayLogs(logs);
  }, [logs]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = displayLogs.slice(indexOfFirstLog, indexOfLastLog);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(displayLogs.length / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (num) => {
    setCurrentPage(num);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      let search = e.target.value.toLowerCase();
      setDisplayLogs(
        logs.filter(
          (elem) =>
            elem.username.toLowerCase().includes(search) ||
            elem.email.toLowerCase().includes(search) ||
            elem.company.toLowerCase().includes(search) ||
            (elem.userType === 0 && "read & write".includes(search)) ||
            (elem.userType === 1 && "read only".includes(search)) ||
            (elem.userType === 2 && "read & write".includes(search)) ||
            (elem.userType === 3 && "topo".includes(search)) ||
            (elem.type === "reactivatedAt" && "reactivated".includes(search)) ||
            (elem.type === "deactivatedAt" && "deactivated".includes(search)) ||
            ("created".includes(search) && elem.type === "createdAt") ||
            new Date(elem.date).toDateString().toLowerCase().includes(search) ||
            (elem.status === 1 && "active".includes(search)) ||
            (elem.status === 0 && "deleted".includes(search))
        )
      );

      setCurrentPage(1);
    } else {
      setDisplayLogs(logs);
    }
  };

  return (
    <Fragment>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Users Log</li>
          </ol>
          <h1 className="page-header">Users Log</h1>

          <div className="card">
            <div className="card-body">
              <div className="form-outline mb-4">
                <input
                  type="search"
                  className="form-control"
                  id="datatable-search-input"
                  placeholder="Search User"
                  onChange={handleSearch}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table id="product-listing" className="table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>User Type</th>
                          <th>Company</th>
                          <th>Action Type</th>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <tr className="text-center">
                            <td colSpan={7}>
                              <ThreeDots
                                height="35"
                                width="35"
                                radius="9"
                                color="rgba(0, 0, 0, .4)"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{ justifyContent: "center" }}
                                visible={true}
                              />
                            </td>
                          </tr>
                        ) : currentLogs.length > 0 ? (
                          currentLogs.map((elem, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{elem.username}</td>
                              <td>{elem.email}</td>
                              <td>
                                {elem.userType === 0 || elem.userType == 2
                                  ? "Read & Write"
                                  : elem.userType == 1
                                  ? "Read Only"
                                  : elem.userType == 3
                                  ? "Topo"
                                  : "N/A"}
                              </td>
                              <td>{elem.company}</td>
                              <td>
                                {elem.type === "reactivatedAt"
                                  ? "Reactivated"
                                  : elem.type === "deactivatedAt"
                                  ? "Deactivated"
                                  : "Created"}
                              </td>
                              <td>{new Date(elem.date).toDateString()}</td>
                              <td>
                                {elem.status === 1 ? (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "green" }}
                                  >
                                    Active
                                  </span>
                                ) : (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "red" }}
                                  >
                                    Deleted
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={7}>No Record Found..</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="gt-pagination"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ul className="pagination">
                      {pageNumbers.map((number, key) => (
                        <li
                          className={
                            currentPage === number
                              ? "page-item active"
                              : "page-item"
                          }
                          aria-current="page"
                          key={key}
                        >
                          <span
                            className="page-link"
                            onClick={() => paginate(number)}
                          >
                            {number}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="filter-pages"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="selection" style={{ marginBottom: "0" }}>
                        Logs Per Page :
                      </label>
                      <select
                        className="selection"
                        style={{
                          outline: "0",
                          borderWidth: "0 0 1px",
                          borderColor: "black",
                          marginLeft: "10px",
                        }}
                        onChange={(e) => setLogsPerPage(e.target.value)}
                      >
                        {rows.map((value, key) => (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
};

export default UsersLog;
