export const add = (e) => {
  return { type: "ADD", data: e };
};
export const name = (e) => {
  return { type: "NAME", data: e };
};
export const id = (e) => {
  return { type: "ID", data: e };
};
export const all = () => {
  return { type: "ALL" };
};
