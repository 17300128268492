import React from "react";
import { Route, Routes } from "react-router-dom";
import Company from "./pages/Company/Company";
import Dashboard from "./pages/Dashboard/Dashboard";
import DetailedDashboard from "./pages/Dashboard/DetailedDashboard";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Users from "./pages/User/Users";
import UsersLog from "./pages/UserLog/UsersLog";

const Markup = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/detailed-dashboard" element={<DetailedDashboard />} />
      <Route path="/company" element={<Company />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users-log" element={<UsersLog />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default Markup;
