import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { add } from "../Redux/actions/index";
import "../layout/login.css";

const Login = () => {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const [check, setCheck] = useState(false);
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);

  const InputEvent = (e) => {
    const newLoginInfo = { ...loginInfo };
    newLoginInfo[e.target.name] = e.target.value;
    setLoginInfo(newLoginInfo);
  };

  const handleRememberMe = (e) => {
    if (e.target.checked) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const { email, password } = loginInfo;

      const myurl =
        `${process.env.REACT_APP_base_url}` + "api/masterAdmin/login";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
      bodyFormData.append("email", email.trim());
      bodyFormData.append("password", password);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          // console.log(response);
          if (response.data.success == true) {
            sessionStorage.setItem("TES_Admin_ID", response.data.data.id);
            sessionStorage.setItem("TES_Admin_EMAIL", response.data.data.email);

            sessionStorage.setItem(
              "TES_Admin_Name",
              response.data.data.username
            );
            sessionStorage.setItem("TES_Admin_PASS", loginInfo.password);

            localStorage.setItem("TES_Admin_EMAIL", response.data.data.email);
            localStorage.setItem("TES_Admin_PASS", loginInfo.password);

            toast.success("Login Successfully...!");
            if (check === true) {
              localStorage.setItem("TES_Admin_Remember_Me", "Enabled");
            } else {
              localStorage.setItem("TES_Admin_Remember_Me", "Disabled");
            }
            navigate(`/dashboard`);
            dispatcher(add({}));
          } else {
            setDisable(false);
            toast.error("Your Email Id and Password does not match...!");
          }
        })
        .catch((error) => {
          //console.log("Errors", error);
        });
    }
  };

  const validate = () => {
    let input = loginInfo;
    let errors = {};
    let isValid = true;
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!input["email"].trim() || !regEmail.test(input["email"].trim())) {
      isValid = false;
      errors["email_err"] = "Please Enter Email.";
    }
    if (!input["password"].trim()) {
      isValid = false;
      errors["password_err"] = "Please Enter Password.";
    }
    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    if (sessionStorage.getItem("TES_Admin_ID") != null) {
      toast.error("Already Logined...!");
      navigate(`/dashboard`);
    }
    if (localStorage.getItem("TES_Admin_Remember_Me") === "Enabled") {
      setCheck(true);
      setLoginInfo({
        email: localStorage.getItem("TES_Admin_EMAIL"),
        password: localStorage.getItem("TES_Admin_PASS"),
      });
    }
  }, []);

  return (
    <Fragment>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>
      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div>
      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassname="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <span className="logo"></span> <b>SV</b> Super Admin
              <small>Login for SpatialVision super admin panel</small>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>
          <div className="login-content">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Email Address"
                  name="email"
                  onChange={InputEvent}
                  value={loginInfo.email}
                />
                <div className="text-danger">{errors.email_err}</div>
              </div>
              <div className="form-group m-b-20">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  placeholder="Password"
                  name="password"
                  onChange={InputEvent}
                  value={loginInfo.password}
                />
                <Link
                  to="/forgot-password"
                  style={{
                    color: "white",
                    marginLeft: "250px",
                    textDecoration: "underline",
                  }}
                >
                  Forgot Password
                </Link>
                <div className="text-danger">{errors.password_err}</div>
              </div>
              <div className="form-group m-b-20">
                <input
                  type="checkbox"
                  checked={check}
                  value={check}
                  onChange={handleRememberMe}
                />{" "}
                Remember Me
              </div>
              <div className="login-buttons">
                <button
                  type="submit"
                  className="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Sign In"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
