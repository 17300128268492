import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../layout/Footer";
import Loader from "../../layout/Loader";
import Menu from "../../layout/Menu";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [displayUsers, setDisplayUsers] = useState([]);
  const rows = [10, 20, 30];
  const [userPerPage, setUserPerPage] = useState(rows[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    getUserData();
  }, []);

  const getUserData = () => {
    const myurl = `${process.env.REACT_APP_base_url}` + "api/masterAdmin/users";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("company", sessionStorage.getItem("TES_Admin_Company"));

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        //console.log(response);
        if (response.data.success === true) {
          let res = response.data.data;
          let arr = [];
          res.map((elem, index) => {
            arr.push({ ...elem, indexs: index + 1 });
          });
          setUsers(arr);
        }
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
      .catch((error) => {
        console.log("Errors", error);
        setLoader(false);
      });
  };

  const handleUserStatusChange = (user) => {
    const myurl =
      `${process.env.REACT_APP_base_url}` +
      "api/masterAdmin/user-status-update";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("user_id", user._id || user.id);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        //console.log(response);
        if (response.data.success === true) {
          const updatedUsers = users.map((u) => {
            if (u._id === user._id) {
              return response.data.user;
            }
            return u;
          });
          setUsers(updatedUsers);
          toast.success("User status updated successfully!");
        }
      })
      .catch((error) => {
        console.log("Errors", error);
      });
  };

  useEffect(() => {
    setDisplayUsers(users);
  }, [users]);

  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = displayUsers.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(displayUsers.length / userPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (num) => {
    setCurrentPage(num);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      let search = e.target.value.toLowerCase();
      setDisplayUsers(
        users.filter(
          (elem) =>
            elem.username.toLowerCase().includes(search) ||
            elem.email.toLowerCase().includes(search) ||
            elem.company.toLowerCase().includes(search) ||
            (elem.type === 0 && "read & write".includes(search)) ||
            (elem.type === 1 && "read only".includes(search)) ||
            (elem.type === 2 && "read & write".includes(search)) ||
            (elem.type === 3 && "topo".includes(search)) ||
            ("created".includes(search) && elem.type === "createdAt") ||
            new Date(elem.date).toDateString().toLowerCase().includes(search) ||
            (elem.status === 1 && "active".includes(search)) ||
            (elem.status === 0 && "deleted".includes(search))
        )
      );

      setCurrentPage(1);
    } else {
      setDisplayUsers(users);
    }
  };

  return (
    <Fragment>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Users</li>
          </ol>
          <h1 className="page-header">Users</h1>

          <div className="card">
            <div className="card-body">
              <div className="form-outline mb-4">
                <input
                  type="search"
                  className="form-control"
                  id="datatable-search-input"
                  placeholder="Search User"
                  onChange={handleSearch}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table id="product-listing" className="table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Company</th>
                          <th>User Type</th>
                          <th>Status</th>
                          <th>Created At</th>
                          <th>Deactivated At</th>
                          <th>Reactivated At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <tr className="text-center">
                            <td colSpan={5}>
                              <ThreeDots
                                height="35"
                                width="35"
                                radius="9"
                                color="rgba(0, 0, 0, .4)"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{ justifyContent: "center" }}
                                visible={true}
                              />
                            </td>
                          </tr>
                        ) : currentUsers.length > 0 ? (
                          currentUsers.map((elem, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{elem.username}</td>
                              <td>{elem.email}</td>
                              <td>{elem.company}</td>
                              <td>
                                {elem.type === 0 || elem.type == 2
                                  ? "Read & Write"
                                  : elem.type == 1
                                  ? "Read Only"
                                  : elem.type == 3
                                  ? "Topo"
                                  : "N/A"}
                              </td>
                              <td>
                                {elem.status === 1 ? (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "green" }}
                                  >
                                    Active
                                  </span>
                                ) : (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "red" }}
                                  >
                                    Deleted
                                  </span>
                                )}
                              </td>
                              <td>{new Date(elem.createdAt).toDateString()}</td>
                              <td>
                                {new Date(elem.deactivatedAt).toDateString()}
                              </td>{" "}
                              <td>
                                {new Date(elem.reactivatedAt).toDateString()}
                              </td>
                              <td>
                                {elem.status === 1 ? (
                                  <>
                                    {/* <Link to="/edit-user" state={elem._id}> */}
                                    <i
                                      className="fa fa-toggle-on"
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                        fontSize: "25px",
                                      }}
                                      onClick={() => {
                                        handleUserStatusChange(elem);
                                      }}
                                    ></i>
                                    {/* </Link> */}
                                  </>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    height={25}
                                    fill="red"
                                    onClick={() => {
                                      handleUserStatusChange(elem);
                                    }}
                                  >
                                    <path d="M384 128c70.7 0 128 57.3 128 128s-57.3 128-128 128H192c-70.7 0-128-57.3-128-128s57.3-128 128-128H384zM576 256c0-106-86-192-192-192H192C86 64 0 150 0 256S86 448 192 448H384c106 0 192-86 192-192zM192 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" />
                                  </svg>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={5}>No Record Found..</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="gt-pagination"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ul className="pagination">
                      {pageNumbers.map((number, key) => (
                        <li
                          className={
                            currentPage === number
                              ? "page-item active"
                              : "page-item"
                          }
                          aria-current="page"
                          key={key}
                        >
                          <span
                            className="page-link"
                            onClick={() => paginate(number)}
                          >
                            {number}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="filter-pages"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="selection" style={{ marginBottom: "0" }}>
                        Users Per Page :
                      </label>
                      <select
                        className="selection"
                        style={{
                          outline: "0",
                          borderWidth: "0 0 1px",
                          borderColor: "black",
                          marginLeft: "10px",
                        }}
                        onChange={(e) => setUserPerPage(e.target.value)}
                      >
                        {rows.map((value, key) => (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Users;
