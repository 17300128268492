import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../layout/Loader";
import Menu from "../../layout/Menu";
import Footer from "../../layout/Footer";
import axios from "axios";
export default function DetailedDashboard() {
  let nav = useNavigate();
  let location = useLocation();
  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, [location.state]);
  return (
    <>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Dashboard Details</li>
          </ol>
          <h1 className="page-header">
            <i
              class="fa fa-arrow-left edit"
              onClick={() => {
                nav("/dashboard");
              }}
              style={{ cursor: "pointer" }}
            ></i>
            &nbsp;
            {location.state.title}
          </h1>
          <table class="table table-hover" style={{ backgroundColor: "white" }}>
            <thead>
              <tr>
                <th scope="col">Sr. No</th>

                <th scope="col">Company Name</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: "50vh" }}>
              {location.state.data.length > 0 ? (
                location.state.data?.map((e, i) => (
                  <tr>
                    <td scope="row">{i + 1}</td>
                    <td>{e.name}</td>
                    <td>{e.count}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={3}>No Records.</td>
                </tr>
              )}{" "}
            </tbody>
          </table>
        </div>

        <Footer />
      </div>
    </>
  );
}
