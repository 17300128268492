import React, { useEffect, useState } from "react";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const naviagte = useNavigate();
  const [resetInfo, setResetInfo] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  const InputEvent = (e) => {
    const newResetInfo = { ...resetInfo };
    newResetInfo[e.target.name] = e.target.value;
    setResetInfo(newResetInfo);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);

      const myurl =
        `${process.env.REACT_APP_base_url}` + "api/masterAdmin/forgot-password";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
      bodyFormData.append("email", resetInfo.email.trim());
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response?.data?.success) {
            toast.success("Mail send successfully...!");
            naviagte("/");
          } else {
            setDisable(false);
            toast.error(
              response?.data?.message
                ? response?.data?.message
                : "Invalid inputs...!"
            );
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          toast.error("Something went wrong...!");
          setDisable(false);
        });
    } else {
      setDisable(false);
    }
  };

  const validate = () => {
    let input = resetInfo;
    let errors = {};
    let isValid = true;
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!input["email"].trim() || !regEmail.test(input["email"].trim())) {
      isValid = false;
      errors["email_err"] = "Please Enter Valid Email.";
    }
    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    if (sessionStorage.getItem("TES_Admin_ID") != null) {
      toast.error("Already Logined...!");
      navigate(`/dashboard`);
    }
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  return (
    <>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>

      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div>

      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassName="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <span className="logo"></span> <b>SV</b> Super Admin
              <small style={{ width: "90%" }}>Forgot Password for SpatialVision Super Admin panel</small>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>

          <div className="login-content">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Email Address"
                  name="email"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.email_err}</div>
              </div>
              <div className="login-buttons">
                <button
                  type="submit"
                  class="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Reset Password"}
                </button>
              </div>

              <p style={{ textAlign: "end", paddingTop: "10px" }}>
                Known with Credentials then ,{" "}
                <Link to="/" style={{ textDecoration: "underline" }}>
                  <b>Login</b>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
