import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../layout/Loader";
import Menu from "../layout/Menu";
import Footer from "../layout/Footer";
import axios from "axios";

const Profile = () => {
  const [changepass, setChangePass] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [profileInfo, setProfileInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [disable2, setDisable2] = useState(false);

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    getProfileData();
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);
  const getProfileData = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/get-profile";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("id", sessionStorage.getItem("TES_Admin_ID"));
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success === true) {
          setProfileInfo({
            ...response.data.data,
            filePath: response.data.data.profile_pic.split("/").pop(),
          });
        }
      })
      .catch((error) => {
        //console.log("Errors", error);
      });
  };
  const handleImg = (e) => {
    if (e.target.files[0]) {
      if (
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png"
      ) {
        const myurl =
          `${process.env.REACT_APP_base_url}` + "api/masterAdmin/upload-img";
        var bodyFormData = new FormData();
        bodyFormData.append("image", e?.target?.files[0]);

        axios({
          method: "post",
          url: myurl,
          data: bodyFormData,
        })
          .then((result) => {
            setProfileInfo({
              ...profileInfo,
              profile_pic: result.data.data.url,
              filePath: result.data.data.filepath_url,
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        e.target.value = null;
        toast.error("Upload .jpeg\\ .jpg\\ .png File Only.");
      }
    }
  };

  const InputEvent = (e) => {
    const newProfileInfo = { ...profileInfo };
    newProfileInfo[e.target.name] = e.target.value;
    setProfileInfo(newProfileInfo);
  };

  const handleChange = (e) => {
    const newPass = { ...changepass };
    newPass[e.target.name] = e.target.value;
    setChangePass(newPass);
  };

  const handlePassReset = () => {
    setChangePass({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const myurl =
        `${process.env.REACT_APP_base_url}` + "api/masterAdmin/update-profile";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
      bodyFormData.append("id", sessionStorage.getItem("TES_Admin_ID"));
      bodyFormData.append("username", profileInfo.username.trim());
      bodyFormData.append("email", profileInfo.email.trim());
      bodyFormData.append("profile_pic", profileInfo.filePath);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success === true) {
            sessionStorage.setItem("TES_Admin_Name", profileInfo.username);
            sessionStorage.setItem("TES_Admin_EMAIL", profileInfo.email);
            localStorage.setItem("TES_Admin_EMAIL", profileInfo.email);
            window.location.reload(true);
            setDisable(false);
            toast.success("Profile Updated Successfully.");
          } else {
            setDisable(false);
          }
        })
        .catch((error) => {
          console.log("Errors", error);
        });
    }
  };

  const validate = () => {
    let input = profileInfo;
    let errors = {};
    let isValid = true;
    if (!input["username"].trim()) {
      isValid = false;
      errors["username_err"] = "Please Enter Username";
    }
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!input["email"].trim() || !regEmail.test(input["email"].trim())) {
      isValid = false;
      errors["email_err"] = "Please Enter Email Address";
    }

    if (!input["profile_pic"].trim()) {
      isValid = false;
      errors["profile_pic_err"] = "Please Select Any Profile Pic";
    }
    setErrors(errors);
    return isValid;
  };
  const submitHandlerTwo = (e) => {
    e.preventDefault();
    if (validateTwo()) {
      setDisable2(true);
      const myurl =
        `${process.env.REACT_APP_base_url}` + "api/masterAdmin/change-password";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
      bodyFormData.append("id", sessionStorage.getItem("TES_Admin_ID"));
      bodyFormData.append("old_password", changepass.old_password);
      bodyFormData.append("new_password", changepass.new_password);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          if (response.data.success === true) {
            setDisable2(false);
            toast.success("Password Updated Successfully.");
            localStorage.setItem("TES_Admin_PASS", changepass.new_password);
            setChangePass({
              old_password: "",
              new_password: "",
              confirm_password: "",
            });
          } else {
            setDisable2(false);
            setErrors({ old_password_err: response.data.message });
          }
        })
        .catch((error) => {
          //console.log("Errors", error);
          setDisable2(false);
          toast.error("Invalid Inputs!");
        });
    } else {
      setDisable2(false);
    }
  };
  const validateTwo = () => {
    let input = changepass;
    let errors = {};
    let isValid = true;
    if (!input["old_password"].trim()) {
      isValid = false;
      errors["old_password_err"] = "Please enter old password.";
    }
    if (!input["new_password"].trim()) {
      isValid = false;
      errors["new_password_err"] = "Please enter new password.";
    }
    if (!input["confirm_password"].trim()) {
      isValid = false;
      errors["confirm_password_err"] = "Please enter confirm password.";
    }
    if (input["new_password"].trim() !== input["confirm_password"].trim()) {
      isValid = false;
      errors["confirm_password_err"] =
        "New password and confirm password are not same.";
    }
    if (input["new_password"].trim() === input["old_password"].trim()) {
      isValid = false;
      errors["new_password_err"] =
        "New password should be different from older password.";
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Profile</li>
          </ol>
          <h1 className="page-header">Profile</h1>
          <div className="row">
            <div className="col-xl-6 ui-sortable">
              <div
                className="panel panel-inverse"
                data-sortable-id="form-stuff-10"
              >
                <div className="panel-heading ui-sortable-handle">
                  <h4 className="panel-title">Profile setting</h4>
                </div>
                <div className="panel-body">
                  <form onSubmit={(e) => submitHandler(e)}>
                    <fieldset>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label for="exampleInputName">Username:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputName"
                            placeholder="Enter username here.."
                            name="username"
                            value={profileInfo.username}
                            onChange={InputEvent}
                          />
                          <div className="text-danger">
                            {errors.username_err}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label for="exampleInputName">Email:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputName"
                            placeholder="Enter email here.."
                            name="email"
                            value={profileInfo.email}
                            onChange={InputEvent}
                          />
                          <div className="text-danger">{errors.email_err}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label for="exampleInputImage">Image:</label>
                          <br />
                          {profileInfo.profile_pic ? (
                            <img
                              src={profileInfo.profile_pic}
                              className="form-img__img-preview ml-2"
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "10px",
                                objectFit: "cover",
                              }}
                              alt="Profile_Picture"
                            />
                          ) : (
                            <img
                              src="assets/img/default-user.png"
                              alt="RestoImage"
                              className="form-img__img-preview ml-2"
                              style={{
                                width: "100px",
                                height: "100px",
                                margin: "10px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <br />
                          <input
                            type="file"
                            className="form-control"
                            id="exampleInputImage"
                            onChange={handleImg}
                            accept="image/png, image/gif, image/jpeg"
                          />
                          <div className="text-danger">
                            {errors.profile_pic_err}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-sm btn-success m-r-5"
                        disabled={disable}
                      >
                        {disable ? "Processing..." : "Submit"}
                      </button>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 ui-sortable">
              <div
                className="panel panel-inverse"
                data-sortable-id="form-stuff-10"
              >
                <div className="panel-heading ui-sortable-handle">
                  <h4 className="panel-title">Change Password</h4>
                </div>
                <div className="panel-body">
                  <form onSubmit={(e) => submitHandlerTwo(e)}>
                    <fieldset>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label for="exampleInputOldPass">Old Password:</label>
                          <input
                            type="password"
                            className="form-control"
                            id="exampleInputOldPass"
                            placeholder="Enter old password here.."
                            name="old_password"
                            onChange={handleChange}
                            value={changepass.old_password}
                          />
                          <div className="text-danger">
                            {errors.old_password_err}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label for="exampleInputNewPass">New Password:</label>
                          <input
                            type="password"
                            className="form-control"
                            id="exampleInputNewPass"
                            placeholder="Enter new password here.."
                            name="new_password"
                            onChange={handleChange}
                            value={changepass.new_password}
                          />
                          <div className="text-danger">
                            {errors.new_password_err}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label for="exampleInputConfirmPass">
                            Confirm Password:
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="exampleInputConfirmPass"
                            placeholder="Enter confirm password here.."
                            name="confirm_password"
                            onChange={handleChange}
                            value={changepass.confirm_password}
                          />
                          <div className="text-danger">
                            {errors.confirm_password_err}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-sm btn-success m-r-5"
                        disabled={disable2}
                      >
                        {disable2 ? "Processing..." : "Submit"}
                      </button>
                      <button
                        type="reset"
                        className="btn btn-sm btn-default"
                        onClick={handlePassReset}
                      >
                        Reset
                      </button>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
