import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../layout/Loader";
import Menu from "../../layout/Menu";
import Footer from "../../layout/Footer";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

function Dashboard() {
  const [users, setUsers] = useState({ status: false, data: [] });
  const [houses, setHouses] = useState({ status: false, data: [] });
  const [visits, setVisits] = useState({ status: false, data: [] });
  const [templates, setTemplates] = useState({ status: false, data: [] });
  const [managers, setManagers] = useState({ status: false, data: [] });
  const [flags, setFlags] = useState({ status: false, data: [] });
  const [editorList, setEditorList] = useState({ status: false, data: [] });
  const [company, setCompany] = useState({ status: false, data: [] });
  const [statusList, setStatusList] = useState({ status: false, data: [] });

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    getTotalUser();
    getTotalHouses();
    getTotalVisits();
    getTotalTemplates();
    getTotalManagers();
    getTotalFlags();
    getEditorList();
    getTotalCompany();
    getStatusList();
  }, []);
  const getTotalUser = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/dashboard/get-users-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setUsers({ status: true, data: response.data.data });
      }
    });
  };
  const getTotalCompany = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/admins-list";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setCompany({ status: true, data: response.data.data });
      }
    });
  };

  const getTotalHouses = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/dashboard/get-houses-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setHouses({ status: true, data: response.data.data });
      }
    });
  };

  const getTotalVisits = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/dashboard/get-visits-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setVisits({ status: true, data: response.data.data });
      }
    });
  };

  const getTotalTemplates = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` +
      "api/dashboard/get-templates-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setTemplates({ status: true, data: response.data.data });
      }
    });
  };
  const getTotalManagers = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/dashboard/get-managers-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setManagers({ status: true, data: response.data.data });
      }
    });
  };
  const getTotalFlags = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/dashboard/get-flags-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setFlags({ status: true, data: response.data.data });
      }
    });
  };
  const getStatusList = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/status/get-status-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      // console.log(response);
      if (response.data.success === true) {
        setStatusList({ status: true, data: response.data.data });
      }
    });
  };
  const getEditorList = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/dashboard/get-editors-master";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      //console.log(response);
      if (response.data.success === true) {
        setEditorList({ status: true, data: response.data.data });
      }
    });
  };
  function userData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      users.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;

          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });

    return data;
  }
  function templateData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      templates.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;

          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });

    return data;
  }
  function managerData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      managers.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;

          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });

    return data;
  }
  function flagData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      flags.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;

          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });

    return data;
  }
  function editorData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      editorList.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;

          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });

    return data;
  }
  function statusData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      statusList.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;
          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });

    return data;
  }
  function visitsData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      visits.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;

          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });

    return data;
  }
  function housesData() {
    let data = [];
    company.data.map((e) => {
      let name = "",
        count = 0;
      houses.data.map((e1) => {
        if (e1?.company == e.id) {
          name = e.username;

          count++;
        }
      });
      if (name != "" && count != 0) data.push({ name, count });
    });
    return data;
  }
  return (
    <>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
          <h1 className="page-header">Dashboard </h1>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="widget widget-stats bg-info">
                <div className="stats-icon">
                  <i className="fa fa-users"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Users</h4>
                  <p>
                    {users.status ? (
                      users.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Users",
                      data: userData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="widget widget-stats bg-purple">
                <div className="stats-icon">
                  <i class="fa fa-home"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Houses</h4>
                  <p>
                    {houses.status ? (
                      houses.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Houses",
                      data: housesData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="widget widget-stats bg-blue">
                <div className="stats-icon">
                  <i class="fa fa-handshake"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Visits</h4>
                  <p>
                    {visits.status ? (
                      visits.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Visits",
                      data: visitsData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="widget widget-stats bg-orange">
                <div className="stats-icon">
                  <i className="fa fa-list"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Templates</h4>
                  <p>
                    {templates.status ? (
                      templates.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Templates",
                      data: templateData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="widget widget-stats bg-pink">
                <div className="stats-icon">
                  <i className="fa fa-calendar-check"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Managers</h4>
                  <p>
                    {managers.status ? (
                      managers.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Managers",
                      data: managerData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="widget widget-stats bg-gray">
                <div className="stats-icon">
                  <i className="fa fa-flag"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Flag</h4>
                  <p>
                    {flags.status ? (
                      flags.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Flag",
                      data: flagData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div
                className="widget widget-stats "
                style={{ backgroundColor: "#34D565" }}
              >
                <div className="stats-icon">
                  <i class="fa fa-thumbtack"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Status List</h4>
                  <p>
                    {statusList.status ? (
                      statusList.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Status List",
                      data: statusData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="widget widget-stats bg-yellow">
                <div className="stats-icon">
                  <i class="fa-sharp fa fa-clipboard-list"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Editor List</h4>
                  <p>
                    {editorList.status ? (
                      editorList.data.length
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="rgba(0, 0, 0, .4)"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </p>
                </div>
                <div className="stats-link">
                  <Link
                    to="/detailed-dashboard"
                    state={{
                      title: "Total Editor List",
                      data: editorData(),
                    }}
                  >
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
