import { type } from "@testing-library/user-event/dist/type";

const initialState = { id: "", name: "", pages: [] };

export const changeNumber = (state = initialState, action) => {
  switch (action.type) {
    case "ADD":
      return (state = { ...state, pages: action.data });
    case "NAME":
      return (state = { ...state, name: action.data });
    case "ID":
      return (state = { ...state, id: action.data });
    case "ALL":
      return (state = initialState);
    default:
      return state;
  }
};
