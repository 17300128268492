import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../layout/Footer";
import Loader from "../../layout/Loader";
import Menu from "../../layout/Menu";
import Add from "./Add";
import Update from "./Update";

const Company = () => {
  const [users, setUsers] = useState([]);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alert2, setAlert2] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [changed, setChanged] = useState(false);
  const [updateId, setUpdateId] = useState();
  const rows = [10, 20, 30];
  const [userPerPage, setUserPerPage] = useState(rows[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [managerModal, setManagerModal] = useState(false);
  const [managerModal1, setManagerModal1] = useState(false);
  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    getUserData();
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  const getUserData = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/admins-list";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      if (response.data.success === true) {
        let res = response.data.data;
        let arr = [];
        res.map((elem, index) => {
          arr.push({ ...elem, indexs: index + 1 });
        });
        setUsers(arr);
      }
    });
  };

  useEffect(() => {
    setDisplayUsers(users);
  }, [users]);

  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = displayUsers.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(displayUsers.length / userPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (num) => {
    setCurrentPage(num);
  };

  const handleDeleteUser = (id) => {
    setAlert(true);
    setDeleteId(id);
  };

  const DeleteUser = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/delete-admin";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("id", deleteId);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success == true) {
          setDeleteId("");
          setAlert(false);
          setAlert2(true);
        }
      })
      .catch((error) => {
        //console.log("Errors", error);
      });
  };
  const handleUserLogin = (id) => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/company-login";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("userId", id);
    bodyFormData.append("masterId", sessionStorage.getItem("TES_Admin_ID"));

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success == true) {
          window.open(
            process.env.REACT_APP_COMPANY_URL + response.data.data.token
          );
        }
      })
      .catch((error) => {
        // console.log("Errors", error);
        toast.error("Something went wrong");
      });
  };
  const handleCompanyUserLogin = (id) => {
    const myurl =
      `${process.env.REACT_APP_base_url}` +
      "api/masterAdmin/company-user-login";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("company", id);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success == true) {
          window.open(
            process.env.REACT_APP_COMPANY_USER_URL + response.data.data.token
          );
        }
      })
      .catch((error) => {
        // console.log("Errors", error);
        toast.error("Something went wrong");
      });
  };
  const handleSearch = (e) => {
    if (e.target.value) {
      let search = e.target.value;
      setDisplayUsers(
        users.filter(
          (elem) =>
            elem.username.toLowerCase().includes(search.toLowerCase()) ||
            elem.email.toLowerCase().includes(search.toLowerCase())
        )
      );

      setCurrentPage(1)
    } else {
      setDisplayUsers(users);
    }
  };
  useEffect(() => {
    if (changed) {
      getUserData();
    }
  }, [changed]);
  return (
    <Fragment>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <Add
          activeModal={managerModal}
          setActiveModal={() => setManagerModal(false)}
          setUpdateModal={() => setChanged(true)}
        />
        <Update
          activeModal={managerModal1}
          setActiveModal={() => setManagerModal1(false)}
          setUpdateModal={() => setChanged(true)}
          handler={managerModal1}
          data={updateId}
        />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Company</li>
          </ol>
          <h1 className="page-header">
            Company{" "}
            <div
              className="btn btn-success"
              onClick={() => {
                setChanged(false);
                setManagerModal(true);
              }}
            >
              <i className="fa fa-plus"></i>
            </div>
          </h1>
          <div className="card">
            <div className="card-body">
              <div class="form-outline mb-4">
                <input
                  type="search"
                  class="form-control"
                  id="datatable-search-input"
                  placeholder="Search User"
                  onChange={handleSearch}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table id="product-listing" className="table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Username</th>
                          <th>Email</th>
                          {/* <th>Phone Number</th> */}
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.length > 0 ? (
                          currentUsers.map((elem, index) => (
                            <tr key={index}>
                              <td>{elem.indexs}</td>
                              <td>{elem.username}</td>
                              <td>{elem.email}</td>
                              {/* <td>{elem.phoneNumber}</td> */}
                              <td>
                                {elem.status === 1 ? (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "green" }}
                                  >
                                    Active
                                  </span>
                                ) : (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "red" }}
                                  >
                                    Deleted
                                  </span>
                                )}
                              </td>
                              <td>
                                {elem.status === 1 ? (
                                  <>
                                    <i
                                      class="fa fa-globe mb-0"
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Company Panel"
                                      onClick={() => handleUserLogin(elem.id)}
                                    ></i>
                                    <i
                                      class="fa fa-user"
                                      style={{
                                        marginLeft: "12px",

                                        color: "gray",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="User Panel"
                                      onClick={() =>
                                        handleCompanyUserLogin(elem.id)
                                      }
                                    ></i>
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        marginLeft: "12px",

                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUpdateId(elem);
                                        setChanged(false);

                                        setManagerModal1(true);
                                      }}
                                    ></i>
                                    <i
                                      className="fa fa-trash"
                                      onClick={() => handleDeleteUser(elem.id)}
                                      style={{
                                        marginLeft: "12px",
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={6}>No Record Found..</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="gt-pagination"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ul class="pagination">
                      {pageNumbers.map((number) => (
                        <li
                          class={
                            currentPage === number
                              ? "page-item active"
                              : "page-item"
                          }
                          aria-current="page"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <span
                            class="page-link"
                            onClick={() => paginate(number)}
                          >
                            {number}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="filter-pages"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="selection" style={{ marginBottom: "0" }}>
                        Users Per Page :
                      </label>
                      <select
                        className="selection"
                        style={{
                          outline: "0",
                          borderWidth: "0 0 1px",
                          borderColor: "black",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setUserPerPage(e.target.value)}
                      >
                        {rows.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {alert === true ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={DeleteUser}
            onCancel={() => {
              setAlert(false);
              setDeleteId("");
            }}
          >
            You will not be able to recover this Company!
          </SweetAlert>
        ) : (
          ""
        )}
        {alert2 === true ? (
          <SweetAlert
            success
            title="User Deleted Successfully!"
            onConfirm={() => {
              setAlert2(false);
              getUserData();
            }}
          />
        ) : (
          ""
        )}
        <Footer />
      </div>
    </Fragment>
  );
};

export default Company;
