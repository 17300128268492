import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function Update(props) {
  const [data, setData] = useState({
    username: "",
    email: "",
  });
  const [error, setError] = useState({});
  useEffect(() => {
    setData({
      username: props.data?.username.trim(),
      email: props.data?.email.trim(),
      id: props.data?.id.trim(),
    });
  }, [props.data?.id, props.handler]);

  const [disable, setDisable] = useState(false);
  function validate() {
    let error = {},
      isValid = true;
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (data.username.trim() == "") {
      isValid = false;
      error["username"] = "Enter Valid username.";
    }
    if (
      data.email.trim() == "" ||
      !data.email ||
      !regEmail.test(data.email.trim())
    ) {
      isValid = false;
      error["email"] = "Enter Valid Email.";
    }
    setError(error);
    return isValid;
  }
  function submitHandler() {
    setDisable(true);
    if (validate()) {
      const myurl2 =
        `${process.env.REACT_APP_base_url}` + "api/masterAdmin/update-admin";
      var bodyFormData2 = new URLSearchParams();
      bodyFormData2.append("auth_code", process.env.REACT_APP_AUTH_CODE);
      bodyFormData2.append("newUsername", data.username.trim());
      bodyFormData2.append("newEmail", data.email.trim());
      bodyFormData2.append("username", props.data?.username.trim());
      bodyFormData2.append("email", props.data?.email.trim());
      bodyFormData2.append("id", data.id);

      axios({
        method: "post",
        url: myurl2,
        data: bodyFormData2,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success === true) {
            props.setActiveModal();

            props.setUpdateModal();

            setDisable(false);
            toast.success("Company Updated Successfully.");
          } else {
            setDisable(false);
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          setDisable(false);
          toast.error(err.response.data.message);
        });
    } else {
      setDisable(false);
    }
  }
  return (
    <>
      <Modal
        show={props.activeModal}
        onHide={props.setActiveModal}
        size="sm"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <span className="modal-header-title" style={{ marginLeft: "100px" }}>
            Edit Admin
          </span>
          <div
            className="close"
            onClick={props.setActiveModal}
            style={{ cursor: "pointer" }}
          >
            <strong>X</strong>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="m-2">
              <label>User Name</label> <br />
              <div className="input-text">
                <input
                  className="form-control w-100 text-black"
                  type="text"
                  value={data.username}
                  onChange={(e) => {
                    let tempErr = error;
                    delete tempErr.username;
                    setError(tempErr);
                    setData({ ...data, username: e.target.value });
                  }}
                />
                <div className="text-danger">{error.username}</div>
              </div>
            </div>
            <div className="m-2">
              <label>Email</label> <br />
              <div className="input-text">
                <input
                  className="form-control w-100 text-black"
                  type="email"
                  value={data.email}
                  onChange={(e) => {
                    let tempErr = error;
                    delete tempErr.email;
                    setError(tempErr);
                    setData({ ...data, email: e.target.value });
                  }}
                />
                <div className="text-danger">{error.email}</div>
              </div>
            </div>
          </form>
          <div
            className="selectionBtn"
            style={{ marginLeft: "80px", marginTop: "10px" }}
          >
            <button
              type="button"
              onClick={props.setActiveModal}
              className="cancelBtn mr-0"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={submitHandler}
              className="submitBtn"
              disabled={disable}
            >
              {disable ? "Saving..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
